import React from "react"
import Sticky from "react-sticky-el"
import SEO from "../components/SEO/index"
import ServiceHero from "../components/Sections/ServiceHero"
import ServiceSection from "../components/Sections/SectionService"
import StyledBackgroundService from "../components/BackgroundService"
import NavBar from "../components/navbar"

const ServicePage = () => {
  return (
    <>
      <SEO
        title="UZMBC"
        description="Multi Business Company - Продукты и услуги"
      />
      <Sticky className="sticky-selector" topOffset={150}>
        <div>
          <NavBar />
        </div>
      </Sticky>
      <StyledBackgroundService>
        <ServiceHero />
      </StyledBackgroundService>
      <ServiceSection />
    </>
  )
}

export default ServicePage
