import React from "react"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Section } from "../CompoundComponents/Section"
import { Box } from "../CompoundComponents/Box"

const ServiceSection = () => {
  return (
    <Section>
      <Columns vcentered tcentered>
        <Column is4>
          <Box>
            <p className="title is-6">Герметики на основе ПВХ</p>
          </Box>
        </Column>
        <Column is4>
          <Box>
            <p className="title is-6">Полиуретановые герметики</p>
          </Box>
        </Column>
        <Column is4>
          <Box>
            <p className="title is-6">Ремонтные шпатлевки</p>
          </Box>
        </Column>
        <Column is4>
          <Box>
            <p className="title is-6">Автомобильные ЛКП</p>
          </Box>
        </Column>
        <Column is4>
          <Box>
            <p className="title is-6">Строительные ЛКП</p>
          </Box>
        </Column>
        <Column is4>
          <Box>
            <p className="title is-6">Дорожные ЛКП</p>
          </Box>
        </Column>
        <Column is4>
          <Box>
            <p className="title is-6">Сухие строительные смеси</p>
          </Box>
        </Column>
        <Column is4>
          <Box>
            <p className="title is-6">Гидрофобный мел</p>
          </Box>
        </Column>
        <Column is4>
          <Box>
            <p className="title is-6">Растворитель (Нефраc)</p>
          </Box>
        </Column>
        <Column is4>
          <Box>
            <p className="title is-6">Обшивка автомобильных сидений</p>
          </Box>
        </Column>
        <Column is4>
          <Box>
            <p className="title is-6">Обшивка для мебелей</p>
          </Box>
        </Column>
        <Column is4>
          <Box>
            <p className="title is-6">Материалы для брезентов </p>
          </Box>
        </Column>
      </Columns>
    </Section>
  )
}

export default ServiceSection
