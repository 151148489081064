import React from "react"
import { Link } from "gatsby"
import { Button } from "../CompoundComponents/Button"
import { Columns, Column } from "../CompoundComponents/Columns"
import { Hero, HeroTitle, HeroBody } from "../CompoundComponents/Hero"

const ServiceHero = () => {
  return (
    <Hero fullheight>
      <HeroBody>
        <Columns vcentered centered>
          <Column is6 tcentered>
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-easing="ease"
            >
              <HeroTitle>Продукты и услуги</HeroTitle>
            </div>
            <div
              data-sal="slide-up"
              data-sal-delay="100"
              data-sal-easing="ease"
            >
              <Link to="/contact">
                <Button primary blackTheme>
                  Написать
                </Button>
              </Link>
            </div>
          </Column>
        </Columns>
      </HeroBody>
    </Hero>
  )
}

export default ServiceHero
