import React, { Children } from "react"
import classNames from "classnames"

export const HeroTitle = ({ children }) => (
  <h2 className="is-size-1 is-size-4-mobile has-text-white is-uppercase has-text-weight-light mb-1">
    {children}
  </h2>
)

export const HeroSubTitle = ({ children }) => (
  <p className="has-text-weight-light mb-2">{children}</p>
)

export const HeroImg = ({ children }) => (
  <figure className="image mb-2">{Children.toArray(children)}</figure>
)

export const HeroBody = ({ children }) => (
  <div className="hero-body has-background-hero">
    <div className="container">{children}</div>
  </div>
)

export const HeroHead = ({ children }) => (
  <div className="hero-head">{Children.toArray(children)}</div>
)

export const Hero = ({ children, fullheight }) => {
  return (
    <section
      className={classNames("hero has-text-centered-mobile", {
        "is-fullheight": fullheight,
      })}
    >
      {children}
    </section>
  )
}
